<template>
  <div>
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button :disabled="isDisabled" type="success" @click="onPressNewBrand('brandData', 'brandRules')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row v-if="!pageStatus.error" v-loading="loading">
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header :title="'Брэнд үүсгэх'"/>
        <div  class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="brandData" :rules="brandRules" ref="brandData">
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол нэр" prop="brand_monName">
                        <el-input v-model="brandData.brand_monName" placeholder="Говь ХХК ..."></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Англи нэр" prop="brand_engName">
                      <el-input v-model="brandData.brand_engName" placeholder="Говь ХХК ..."></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                      <el-col :span="12">
                      <el-form-item label="Нийлүүлэгч" prop="supplier_id">
                        <el-select
                          v-model="brandData.supplier_id"
                          filterable
                          class="block">
                          <el-option
                            v-for="(item, index) in supplierOption"
                            :key="index"
                            :label="item.supplier_monName"
                            :value="item.supplier_id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                       <el-form-item label="Төлөв">
                          <el-switch
                            style="display: block"
                            v-model="brandData.isActive"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            active-text="Идэвхитэй"
                            inactive-text="Идэвхгүй"
                            class="mt10 text-center">
                          </el-switch>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                    <el-form-item label="Шимтгэлийн хувь" prop="fee_percent">
                      <el-input v-model="brandData.fee_percent" placeholder="Шимтгэлийн хувь"></el-input>
                    </el-form-item>
                  </el-col>
                      <el-col :span="12">
                        <el-form-item label="Лого зураг">
                          <el-upload
                            list-type="picture-card"
                            accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                            action=""
                            :limit="1"
                            :on-preview="handlePictureCardPreview"
                            :file-list="tempImages"
                            :auto-upload="false"
                            :on-change="(file) => getFile(file,'image')"
                            :on-remove="(file) => handleRemove(file, 'image')">
                            <span>250x250</span>
                          </el-upload>
                          <loader :active="isUploading" />
                        </el-form-item>
                      </el-col>
                    <el-col :span="24">
                      <el-form-item label="Тайлбар" prop="brand_description">
                        <el-input v-model="brandData.brand_description" placeholder="Улс, түүх"></el-input>
                      </el-form-item>
                    </el-col>
                    </el-row>
                </div>
              </div>
            </el-col>
            <!-- <el-col :span="10">
              <div class="panel">
                <div class="panel-item">
                  <header>Ажиллах цагийн хуваарь</header>
                  <el-form-item :label="day.day" v-for="(day, indexDay) in week" :key="indexDay">
                    <el-time-select
                      :disabled="day.is_weekend"
                      size="mini"
                      placeholder="Нээх цаг"
                      v-model="day.start_time"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '23:59'
                      }">
                    </el-time-select>
                    <el-time-select
                      class="ml5"
                      size="mini"
                      :disabled="day.is_weekend"
                      placeholder="Хаах цаг"
                      v-model="day.end_time"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '23:59'
                      }">
                    </el-time-select>
                    <el-tooltip class="item" effect="light" content="Амрах өдөр" placement="top">
                      <el-checkbox class="ml10" v-model="day.is_weekend" @change="generateDateTime(day)"></el-checkbox>
                    </el-tooltip>
                  </el-form-item>
                </div>
              </div>
            </el-col> -->
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="24" class="text-right">
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" :disabled="isDisabled" @click="onPressNewBrand('brandData', 'brandRules')"
                >Хадгалах</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/brandList">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
    <!-- Image view dialog -->
    <el-dialog :visible.sync="dialogVisible" title="Брэнд зураг">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import services from '../../../helpers/services'
import customHeader from '../../../components/common/customHeader'
import { handlers } from '../../../helpers/custom'
import loader from '../../../components/common/loader'
// import { getUserName } from '../../../utils/auth'
export default {
  components: {
    customHeader: customHeader,
    loader: loader
  },

  created () {
    // getUserName().then(email => {
    //   this.brandData.user_name = email.split('@')[0]
    // })
    this.getSuppliers()
  },
  computed: {
    errorArray () {
      var errors = []
      if (!this.isNewForm) {
        if (this.brandData.brand_monName.length === 0) {
          errors.push('Монгол нэр оруулна уу')
        }
        if (this.brandData.brand_engName.length === 0) {
          errors.push('Англи нэр оруулна уу')
        }
        if (this.brandData.fee_percent.length === 0) {
          errors.push('Шимтгэлийн хувь оруулна уу')
        }
        if (this.brandData.brand_description.length === 0) {
          errors.push('Тайлбар оруулна уу')
        }
        if (this.brandData.supplier_id.length === 0) {
          errors.push('Нийлүүлэгч оруулна уу')
        }
      }
      return errors
    }
  },

  data () {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      isNewForm: true,
      isActive: true,
      isDisabled: false,
      tempImages: [],
      isUploading: false,
      loading: false,
      confirmLeaveForm: false,
      supplierOption: [],
      brandList: [],
      supplierBrand: [],
      pageStatus: {
        error: false,
        message: ''
      },
      brandData: {
        brand_id: '',
        brand_monName: '',
        fee_percent: '',
        brand_engName: '',
        supplier_id: '',
        brand_description: '',
        brand_logo: ''
        // user_name: ''
      },
      brandRules: {
        brand_monName: [
          { required: true, message: 'Брэндийн монгол нэрээ оруулна уу', trigger: 'blur' }
        ],
        supplier_id: [
          { required: true, message: 'Нийлүүлэгчээ сонгоно уу', trigger: 'blur' }
        ],
        brand_engName: [
          { required: true, message: 'Брэндийн англи нэрээ оруулна уу', trigger: 'blur' }
        ],
        fee_percent: [
          { required: true, message: 'Шимтгэлийн хувь оруулна уу', trigger: 'blur' }
        ],
        brand_description: [
          { required: true, message: 'Брэндийн тайлбараа оруулна уу', trigger: 'blur' }
        ]
      }

      // week: [
      //   {
      //     day: 'Даваа',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Мягмар',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Лхагва',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Пүрэв',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Баасан',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Бямба',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Ням',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   }
      // ]
    }
  },

  methods: {
    // generateDateTime (day) {
    //   if (day.is_weekend === true) {
    //     day.start_time = null
    //     day.end_time = null
    //   }
    // },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    handleRemove (file, type) {
      var tempId = 0
      if (type === 'image') {
        this.tempImages.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImages.splice(tempId, 1)
      } else {
        this.$message.error('Уучлаарай алдаа гарлаа!')
      }
    },

    getFile (file, type) {
      handlers.checkImageSize(file, true, 250, 250).then(() => {
        this.isUploading = true
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            this.isUploading = false
            if (response.status === 'success') {
              if (type === 'image') {
                this.tempImages.push({
                  url: response.url
                })
              }
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type)
        this.$message.error(error.message)
      })
    },

    onPressNewBrand (formName) {
      this.isNewForm = false
      var tempImages = []
      this.tempImages.forEach(element => {
        tempImages.push(element.url)
      })
      this.brandData.brand_logo = tempImages
      if (this.errorArray.length === 0) {
        this.isDisabled = true
      }
      const body = JSON.parse(JSON.stringify(this.brandData))
      if (body) {
        body.fee_percent = parseInt(this.brandData.fee_percent)
      }
      this.$refs[formName].validate((valid) => {
        if (valid && this.errorArray.length === 0) {
          // body.work_time = this.generateWorkTime()
          services.addBrand(body).then(data => {
            this.isDisabled = false
            this.alertReporter(data.status === 'success' ? 'Амжилттай' : 'Уучлаарай', data.message, data.status)
            if (data.status === 'success') {
              this.$router.push({ name: 'brandList' })
            }
          })
        } else {
          return false
        }
      })
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },

    getSuppliers () {
      services.getSuppliers('').then(data => {
        this.supplierOption = data.datas
      })
    }

    // generateWorkTime () {
    //   const workingTime = {}
    //   this.week.forEach(day => {
    //     if (day.day === 'Даваа') {
    //       workingTime.monday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Мягмар') {
    //       workingTime.tuesday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Лхагва') {
    //       workingTime.wednesday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Пүрэв') {
    //       workingTime.thursday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Баасан') {
    //       workingTime.friday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Бямба') {
    //       workingTime.saturday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Ням') {
    //       workingTime.sunday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     }
    //   })
    //   return workingTime
    // }
  }

}
</script>

<style></style>
